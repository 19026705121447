var logs = {
  // Cada uma dessas funções puxa um log diferente do banco de Dados baseado no id da estação (stationId)
  // esse stationId vem da página "site" onde eu chamo a função e passo esse parametro

  async day(siteId, startAt, endAt) {
    if (window.debug)
      console.log(
        `0-will get dayLogs: ${JSON.stringify({ siteId, startAt, endAt })}`
      );
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/corsolar/dayLogs");
    const ans = await ref
      .where("stationId", "==", siteId)
      .where("createdAt", ">=", startAt)
      .where("createdAt", "<=", endAt)

      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
  async month(siteId, startAt, endAt) {
    if (window.debug)
      console.log(
        `0-will get monthLogs: ${JSON.stringify({ siteId, startAt, endAt })}`
      );
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/corsolar/monthLogs");
    const ans = await ref
      .where("stationId", "==", siteId)
      .where("createdAt", ">=", startAt)
      .where("createdAt", "<=", endAt)
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
  async year(siteId, startAt, endAt) {
    if (window.debug) console.log("0-will AnnualLogs");
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/corsolar/yearLogs");
    const ans = await ref
      .where("stationId", "==", siteId)
      .where("createdAt", ">=", startAt)
      .where("createdAt", "<=", endAt)
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
  async all(siteId) {
    if (window.debug) console.log("0-will AllLogs");
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/corsolar/allLogs");
    const ans = await ref
      .where("stationId", "==", siteId)
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
};

export default logs;
