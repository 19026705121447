var login = {
  async doLogin(email, password) {
    try {
      const res = await window.firebase
        .auth()
        .signInWithEmailAndPassword(email, password);
      return res;
    } catch (e) {
      console.log(e);
      return e;
    }
  },
  async getUser(uid) {
    const db = window.firebase.firestore();
    var ref = db.collection(window.path + "/stmarie/users");
    let objects = [];
    var ans = await ref
      .where("uid", "==", uid)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          objects.push(doc.data());
          // console.log(doc.id, " => ", doc.data());
        });

        return objects;
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
};
export default login;
