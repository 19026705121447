import firebase from "firebase/app";
import "firebase/auth";

var users = {
  // busca pelos usuarios no banco
  async getAll() {
    if (window.debug) console.log("0-will logsFinais");
    const db = window.firebase.firestore();
    let objects = [];
    var ref = db.collection(window.path + "/stmarie/users");
    const ans = await ref
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
        return objects;
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return ans;
  },
  async getByUid(uid) {
    const db = window.firebase.firestore();
    var ref = db.collection("databases/stmarie/users");
    var objects;
    const ans = await ref
      .where("uid", "==", uid)
      .get()
      .then((querySnapshot) => {
        objects = querySnapshot.docs.map((x) => x.data());
      })
      .catch((error) => {
        if (window.debug) console.log("Error getting documents: ", error);
        return error;
      });
    return objects[0];
  },
  // atualiza os usuarios - refactor this function on future

  async update(user) {
    const db = window.firebase.firestore();
    db.collection("databases/stmarie/users")
      .doc(user.email)
      .update({
        status: user.status,
        name: user.name,
        accessLevel: user.accessLevel,
      })
      .then(() => {
        console.log("Document updated!");
        //   window.location.reload()
      });
  },
  // Patch all fields
  async patch(user) {
    const db = window.firebase.firestore();
    await db
      .collection("databases/stmarie/users")
      .doc(user.email)
      .update(user)
      .then(() => {
        console.log("Document updated!");
        //   window.location.reload()
      });
  },
  // tryCreateUser2 tenta criar um usuário no fireBase, não adicioná-lo em si, mas validar sua autenticação
  async tryCreateUser2(user) {
    try {
      var newUser = await window.firebase
        //acessa a parte de authentication do firebase
        .auth()
        //define o padrão de verificação
        .createUserWithEmailAndPassword(user.email, user.senha)
        // e então executa a seguinte func
        .then((userCredential) => {
          // Signed in

          var userLogged = userCredential.user;
          // ...
          return userLogged;
        });
    } catch (error) {
      // var errorCode = error.code;

      // var errorMessage = error.message;
      return error;
      // ..
    }
    // essas 3 vars são responsáveis por pegar a data exata em que o user foi criado e formata-la
    var data = new Date();
    var options = { day: "numeric", month: "short", year: "numeric" };
    var formatted = data.toLocaleDateString("pt-br", options);
    // esse obj recebe todos os valores que irão para o campor
    //obs: a var newUser são dados recebidos do firebase, já a user são dados que vem da página
    const dados = {
      email: newUser.email,
      uid: newUser.uid, // esse uid é a identificação criada pelo próprio firebase(o id do usuario)
      name: user.name,
      status: "Aguardando Aprovação", // valor padrão, para que quando o usuáriom for cadastrado entre com esse status
      accessLevel: "Cliente", // valor padrão, para que quando o usuáriom for cadastrado entre com esse status
      createdAt: data.getTime(), // aqui é a mesma data de cima, mas em formato de timestamp
      createdAtStr: formatted,
      profileId: 2,
    };
    await users.postUser(dados);

    return newUser;
  },
  async updateUserPassword(user, newPassword, credentials) {
    var condition = {};
    const db = window.firebase.firestore();
    // db.collection("databases/stmarie/users")
    //   .doc(user.email)
    //   .update(user)
    //   .then(() => {
    //     console.log("Document updated!");
    //     //   window.location.reload()
    //   });
    await window.firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then((userCredential) => {
        var userLogged = userCredential.user;
      })
      .catch((error) => {
        condition.valid = false;
        condition.msg = error.message;
      });

    user = window.firebase.auth().currentUser;
    let newCredential = firebase.auth.EmailAuthProvider.credential(
      credentials.email,
      credentials.password
    );

    // await user
    //   .reauthenticateWithCredential(newCredential)
    //   .then(() => {
    //     console.log("usuário reautenticado");
    //   })
    //   .catch((error) => {
    //     condition.valid = false;
    //     condition.msg = error.message;
    //   });

    await user
      .updatePassword(newPassword)
      .then(() => {
        console.log("Password updated");
        condition.valid = true;
        condition.msg = "Password updated";
      })
      .catch((error) => {
        condition.valid = false;
        condition.msg = error.message;
      });

    return condition;
  },
  //função responsável por salvar os dados no banco
  async postUser(dados) {
    const db = window.firebase.firestore();
    var ref = db.collection(window.path + "/stmarie/users");
    //a função set substitui ou cria um novo campo caso o mesmo não exista
    var e = await ref
      .doc(dados.email)
      .set(dados)

      .then((docRef) => {
        if (window.debug)
          console.log(
            "Document successfully updated!" + JSON.stringify(docRef)
          );
        return docRef;
      })

      .catch((error) => {
        console.error("Error writing document: ", error);
      });
    return e;
  },
  async delete(user) {
    const db = window.firebase.firestore();
    //este primeiro delete é para deletar os usuário do documento 'users'
    db.collection(window.path + "/stmarie/users")
      .doc(user.email)
      .delete()
      .then(() => {
        console.log("Document successfully deleted on document 'users'!");
        // este segundo delete é para deletar o usuario do documento 'siteUsers' que o associa a uma usina (site)
        db.collection(window.path + "/stmarie/siteUsers")
          .doc(user.email)
          .delete()
          .then(() => {
            console.log(
              "Document successfully deleted on document 'siteUsers'!"
            );
          });
        // window.location.reload()
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
  },
};
export default users;
