<!-- App.vue -->
<template>
  <v-app fluid ma-0 pa-0>
    <v-dialog v-model="dialog" width="600">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span>Erro ao realizar o login!</span>
        </v-card-title>

        <v-card-text class="text-center"> {{ message }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Sizes your content based upon application components -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid fill-height class="custom-container ma-0 pa-0">
        <v-img
          src="@/assets/img/logoDark.png"
          style="position: fixed; width: 100px; right: 50px; bottom: 50px"
        >
        </v-img>
        <v-row align="center" justify="center">
          <v-col cols="10" xs="10" sm="8" md="4" xl="3">
            <v-card
              class="elevation-12"
              style="background-color: rgb(255, 255, 255, 0.7)"
            >
              <br />
              <v-img
                src="@/assets/img/logoDark.png"
                class="white--text justify-center my-10"
                style="width: 250px; margin: auto"
              >
              </v-img>
              <v-row v-if="modeHomolg">
                <v-spacer></v-spacer>
                <p class="mx-2" style="color: red; margin: auto">Homologação</p>
                <v-spacer></v-spacer>
              </v-row>

              <br />
              <v-card-title primary-title class="justify-center"
                ><h3>Acesse sua conta</h3></v-card-title
              >
              <v-card-text>
                <validation-observer ref="observer" v-slot="{ invalid }">
                  <v-form @submit.prevent="submit" class="ma-3 pa-5">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                    >
                      <v-text-field
                        v-model="email"
                        :error-messages="errors"
                        required
                        prepend-icon="mdi-account"
                        name="email"
                        label="Login"
                        ref="email"
                        color="primary"
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        label="Senha:"
                        hint="Preencha o campo"
                        class="input-group--focused"
                        prepend-icon="mdi-lock"
                        @click:append="show = !show"
                        v-model="password"
                        color="primary"
                      ></v-text-field>
                    </validation-provider>
                  </v-form>
                  <v-card-actions class="ma-3 pa-5">
                    <v-spacer></v-spacer>
                    <v-btn
                      :disabled="invalid"
                      block
                      class="my-0"
                      color="primary"
                      @click="doLogin"
                      draggable="true"
                    >
                      <b v-show="!loading">
                        Entrar
                        <v-icon class="ma-1">mdi-login</v-icon>
                      </b>

                      <v-progress-circular
                        v-show="loading"
                        indeterminate
                        color="white"
                      ></v-progress-circular>
                    </v-btn>
                  </v-card-actions>
                </validation-observer>
                <v-card-actions>
                  <v-row>
                    <v-btn color="primary" text @click="forgotPassowrd">
                      Esqueci minha senha
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="doRegister">
                      Registrar-se
                    </v-btn>
                  </v-row>
                </v-card-actions>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <span style="font-size: 12px">Bankcor</span>
                </v-card-actions>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <br />
          <br />
          <br />
          <br />
        </v-row>
        <v-card
          class="flex ma-0 pa-0"
          style="
            background-color: rgb(23, 27, 102, 0.8);
            position: absolute;
            bottom: 0px;
            width: 100vw;
            margin: 0px;
            padding: 0px;
            z-index: 0;
          "
        >
          <v-card-title>
            <!-- <a
              href="https://www.stmarie.com.br"
              style="color: white; text-decoration: none; margin-left: 20px"
            >
              www.stmarie.com.br</a
            >
            <v-spacer></v-spacer>

            <v-btn
              v-for="link in links"
              :key="link.icon"
              class="mx-4"
              dark
              icon
            >
              <a
                :href="link.href"
                style="color: white; text-decoration: none; margin-left: 20px"
              >
                <v-icon size="24px">
                  {{ link.icon }}
                </v-icon></a
              >
            </v-btn> -->
          </v-card-title>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import firestoreApi from "../../../../libraries/firestore/firestoreApi";

import { mapState, mapMutations } from "vuex";
import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "login",

  data() {
    return {
      saveEmail: false,
      loading: false,
      dialog: false,
      message: null,
      email: "",
      password: "",
      loading: false,
      show: false,
      links: [
        { href: "https://www.facebook.com/stmarietrade", icon: "mdi-facebook" },
        {
          href: "https://www.instagram.com/saintemarietrading/",
          icon: "mdi-instagram",
        },
        {
          href: "https://www.linkedin.com/company/sainte-marie-importacao-e-exportacao?trk=similar-companies_org_title",
          icon: "mdi-linkedin",
        },
      ],
    };
  },

  created() {},
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
    }),
    modeHomolg() {
      return `${process.env.VUE_APP_ENV}` == "HOMOLOG";
    },
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
    isScreenLarge() {
      // Adjust the breakpoint according to your needs
      return window.innerWidth >= 600; // Example breakpoint: 768px
    },
  },

  async mounted() {
    if (localStorage.email) this.email = localStorage.email;
    this.saveEmail = localStorage.saveEmail;
  },
  methods: {
    ...mapMutations({
      setProcesses: "SET_PROCESSES",
      setChipsEncomendantes: "SET_CHIPS_ENCOMENDANTES",
    }),
    async forgetPassowrd() {
      this.$firebase.auth().sendPasswordResetEmail(this.email);
    },

    async doRegister() {
      this.$router.push("/register");
    },
    async forgotPassowrd() {
      //this.loading = true;
      let res = "";
      let user;
      const { email } = this;

      if (!email) {
        this.message = "Favor preencher email válido";
        this.dialog = true;
      }

      try {
        res = await strapiApi.users.forgotPassword(email);
        console.log(JSON.stringify({ res }));
        if (res?.error) {
          //console.log(res.error.message);
          this.message = res.error.message;
          this.dialog = true;
          return;
        }

        if (res?.ok) {
          this.message = "Enviado email para redefinição de senha";
          this.dialog = true;
          return;
        }
      } catch (e) {
        console.log(e);
        this.message = e;
        this.dialog = true;
        return;
      }
    },

    async doLogin() {
      this.loading = true;
      let res = "";
      let user;

      const { email, password } = this;
      try {
        //res = await corsolarApi.logIn.doLogin(email, password);
        res = await strapiApi.users.login(email, password);
        console.log(JSON.stringify({ res }));
        //get data from user
        console.log(JSON.stringify(res.error));
        if (res.error) {
          console.log(res.error.message);
          this.message = res.error.message;
          this.dialog = true;
          this.loading = false;
          return;
        }
        user = await strapiApi.users.getById(res.id);
        console.log(JSON.stringify({ user }));
        if (!user || user?.role?.id == 1) {
          console.log(JSON.stringify({ user }));
          this.message = "Aguardando aprovação";
          this.dialog = true;
          this.loading = false;
          return;
        }

        this.$store.commit("SET_USER", user);
        this.setProcesses([]);
        this.setChipsEncomendantes([]);
        this.$router.push("/dashboard");
        this.loading = false;
      } catch (e) {
        console.log(e);
        this.message = e;
        this.dialog = true;
        this.loading = false;
        return;
      }
    },
    async getUser(uid) {},
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
