<template>
  <v-app>
    <v-dialog v-model="showDialog" width="600">
      <v-card>
        <v-card-title class="headline font-weight-bold">
          <span>Erro ao realizar o cadastro!</span>
        </v-card-title>

        <v-card-text class="text-center"> {{ errorMessage }} </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showDialog = false"> OK </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- style="
        background-color: gray;
        background: url('https://mxwqlouaaupkgunfdbku.supabase.co/storage/v1/object/public/test/containers.png')
          no-repeat center center fixed !important;
        background-size: cover;
      " -->
    <v-container fluid fill-height class="grey lighten-4 custom-container pa-0">
      <v-img
        src="@/assets/img/logoDark.png"
        style="position: fixed; width: 300px; right: 50px; bottom: 50px"
      >
      </v-img>

      <v-row align="center" justify="center">
        <v-col xs="10" sm="6" md="4" xl="4" class="pa-3">
          <v-row justify="center">
            <v-col cols="12">
              <v-card
                :loading="loading"
                elevation="12"
                style="background-color: rgb(255, 255, 255, 0.7)"
              >
                <div class="">
                  <br />
                  <v-img
                    src="@/assets/img/logoDark.png"
                    class="white--text justify-center my-10"
                    style="width: 250px; margin: auto"
                  >
                  </v-img>
                  <br />
                </div>
                <v-card-text>
                  <validation-observer ref="observer" v-slot="{ invalid }">
                    <v-form @submit.prevent="submit" class="ma-3">
                      <!-- Campo Email -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <v-text-field
                          v-model="email"
                          :error-messages="errors"
                          required
                          label="E-mail:"
                          prepend-icon="mdi-email"
                          color="primary"
                        ></v-text-field>
                      </validation-provider>

                      <!-- Campo username -->
                      <validation-provider
                        v-slot="{ errors }"
                        name="Name"
                        rules="required|max:25"
                      >
                        <v-text-field
                          v-model="username"
                          :counter="25"
                          :error-messages="errors"
                          label="Nome:"
                          required
                          prepend-icon="mdi-account"
                          color="primary"
                        ></v-text-field>
                      </validation-provider>

                      <!-- Campo senha -->
                      <v-text-field
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="show ? 'text' : 'password'"
                        label="Senha:"
                        hint="Preencha o campo"
                        class="input-group--focused"
                        prepend-icon="mdi-lock"
                        @click:append="show = !show"
                        v-model="password"
                        :rules="passwordRules"
                        color="primary"
                      ></v-text-field>

                      <v-text-field
                        :append-icon="showConfirm ? 'mdi-eye' : 'mdi-eye-off'"
                        :type="showConfirm ? 'text' : 'password'"
                        label="Confirme a senha:"
                        hint="Confirme a senha:"
                        class="input-group--focused"
                        prepend-icon="mdi-lock"
                        v-model="passwordConfirmation"
                        @click:append="showConfirm = !showConfirm"
                        :rules="confirmPasswordRules"
                        color="primary"
                      ></v-text-field>

                      <v-btn
                        :disabled="invalid && !creatingUser"
                        @click="
                          registerUser(
                            username,
                            email,
                            password,
                            passwordConfirmation
                          )
                        "
                        block
                        class="actionButton font-weight-bold mt-3"
                      >
                        Cadastrar
                        <v-progress-circular
                          v-show="creatingUser"
                          indeterminate
                          color="white"
                        ></v-progress-circular>
                      </v-btn>
                    </v-form>
                  </validation-observer>
                  <v-spacer>
                    <v-btn
                      color="actionButton"
                      text
                      small
                      block
                      @click="backToLogin"
                    >
                      <span class="grey--text text--darken-1"
                        >Já possui conta?</span
                      >
                      <span class="ml-2 primary--text">ENTRE</span>
                    </v-btn>
                  </v-spacer>
                  <v-alert
                    type="success"
                    icon="mdi-check"
                    class="pa-2"
                    v-model="successAlert"
                  >
                    Solicitação enviada com sucesso
                  </v-alert>
                  <v-alert
                    type="error"
                    icon="mdi-alert"
                    class="pa-2"
                    v-model="errorAlert"
                    >{{ errorMsg }}</v-alert
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-snackbar
        v-model="loadingSnackBar"
        shaped
        absolute
        :timeout="timeout"
        >{{ snackbarText }}</v-snackbar
      >
      <v-card
        class="flex ma-0 pa-0"
        style="
          background-color: rgb(23, 27, 102, 0.8);
          position: absolute;
          bottom: 0px;
          width: 100vw;
          margin: 0px;
          padding: 0px;
        "
      >
        <v-card-title>
          <a
            href="https://www.stmarie.com.br"
            style="color: white; text-decoration: none; margin-left: 20px"
          >
            www.stmarie.com.br</a
          >
          <v-spacer></v-spacer>

          <v-btn v-for="link in links" :key="link.icon" class="mx-4" dark icon>
            <a
              :href="link.href"
              style="color: white; text-decoration: none; margin-left: 20px"
            >
              <v-icon size="24px">
                {{ link.icon }}
              </v-icon></a
            >
          </v-btn>
        </v-card-title>
      </v-card>
    </v-container>
  </v-app>
</template>
<script>
import corsolarApi from "../../../../libraries/corsolar/corsolarApi";
import strapiApi from "../../../../libraries/strapi/strapiApi";
export default {
  name: "Register",

  data() {
    return {
      snackbarText: "Usuário Criado com Sucesso!",
      loadingSnackBar: false,
      timeout: 5000,
      loading: false,
      showDialog: false,
      errorMessage: "",
      username: "",
      email: "",
      user: {},
      returnMessage: "",
      show: false,
      showConfirm: false,
      successAlert: false,
      saveAlert: false,
      errorAlert: false,
      creatingUser: false,
      errorMsg: "",

      password: "",
      passwordConfirmation: "",
      // aqui é feito a verificação da senha
      passwordRules: [
        (valuepass) => !!valuepass || "Insira uma senha.",
        (valuepass) =>
          /(?=.*[A-Z])/.test(valuepass) ||
          "Insira no mínimo uma letra maiúscula",
        (valuepass) =>
          /(?=.*\d)/.test(valuepass) || "insira no mínimo um número",
        (valuepass) =>
          /([!@$%])/.test(valuepass) ||
          "Insira no mínimo um caractére especial",
        (valuepass) => valuepass.length >= 8 || "Mínimo de 8 caracteris",
      ],
      // aqui onde é feita a verificação para ver se o user digitou a mesma senha
      confirmPasswordRules: [
        (value) => !!value || "Confirme a senha.",
        (value) => value === this.password,
      ],
      links: [
        { href: "https://www.facebook.com/stmarietrade", icon: "mdi-facebook" },
        {
          href: "https://www.instagram.com/saintemarietrading/",
          icon: "mdi-instagram",
        },
        {
          href: "https://www.linkedin.com/company/sainte-marie-importacao-e-exportacao?trk=similar-companies_org_title",
          icon: "mdi-linkedin",
        },
      ],
    };
  },
  created() {},
  computed: {},

  async mounted() {},
  methods: {
    // função chaamada para salvar o user no banco
    async registerUser(username, email, password, passwordConfirmation) {
      this.loading = true;
      this.user = {
        username: username,
        email: email,
        password: password,
        passwordConfirmation: passwordConfirmation,
      };
      // aqui chama a func que salavará o user e fará a auth do mesmo. Entre em users.js para mais info.
      //let res = await corsolarApi.users.tryCreateUser2(this.user);
      let res = await strapiApi.users.register(
        username,
        email,
        password,
        passwordConfirmation
      );

      await this.openAlerts(res);
      this.updatingPassword = false;
      this.creatingUser = false;
      console.log(JSON.stringify({ res }));
    },
    closeAlerts() {
      this.saveAlert = false;
      this.errorAlert = false;
      this.successAlert = false;
    },
    async openAlerts(condition) {
      if (condition.jwt) {
        this.successAlert = true;
      } else if (condition?.error) {
        this.errorAlert = true;
        this.errorMsg = condition?.error?.message;
      }
      setTimeout(this.closeAlerts, 2000);
    },
    //   ao clicar no botao de 'ja tenho conta ' volta para a tela de login
    backToLogin() {
      this.$router.push("/login");
    },
    async redirect() {
      this.$router.push("/login");
      // let res = "";
      // const { email, senha } = this;
      // try {
      //   res = await corsolarApi.logIn.doLogin(email, senha);
      //   this.$store.commit("SET_TOKEN", res.user.uid);

      //   // return res
      // } catch (e) {
      //   console.log(e);
      //   this.dialog = true;
      // }
      // this.loading = false;
      // this.getUser(res.user.uid);
    },
    // async getUser(uid) {
    //   const res = await corsolarApi.logIn.getUser(uid);
    //   this.$store.commit("SET_ACCESS", res[0].status);
    //   this.$store.commit("SET_USER", res[0]);

    //   if (this.$store.state.auth.user.status == "Aguardando Aprovação") {
    //     this.$router.push("/waiting");
    //   } else {
    //     this.$router.push("/stations");
    //   }
    // },
  },
};
</script>

<style scoped>
.custom-container {
  background-image: url("~@/assets/img/background.png");
  background-size: cover;
}
</style>
