import users from "./users";
import updateSites from "./updateSites";
import logs from "./logs/logs";
import inverters from "./inverters";
import logIn from "./logIn";
import alarms from "./alarms";
import dateFunctions from "./dateFunctions";
var corsolarApi = {
  logs,
  users,
  updateSites,
  inverters,
  logIn,
  alarms,
  dateFunctions,
};

export default corsolarApi;
